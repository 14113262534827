import { useEffect, useState } from 'react';
import { useAppointment } from '../../context/appointmentContext';
import { useUser } from '../../context/userContext';
import { IbisOptician, IbisPatient } from '../../helper/IbisUser';
import { useModal } from '../../hooks';
import AppointmentModal from '../Modals/AppointmentModal';
import AppointmentsList from './AppointmentsList';
import PatientNextApptCard from './PatientNextApptCard';

const AppointmentContent = () => {
  const {
    allAppointments,
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    filter,
    handleFilterChange,
    dateTimeFilter,
    handleDateTimeFilterChange,
    sortField,
    sortDirection,
    handleSort,
    resetAllFilters,
    setFiltersObject,
    fetchAllAppointments,
    filtersObject
  } = useAppointment();
  const [showCompletedAppointments, toggleShowCompletedAppointments] = useState(false);
  const { getUsers } = useUser();
  const { modalIsOpen, toggle } = useModal();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  useEffect(() => {
    const fetchUsers = async () => {
      await getUsers(new IbisPatient());
      if (loggedUser?.role !== 'patient') await getUsers(new IbisOptician());
    };
    fetchUsers();
  }, [loggedUser?.role]);

  const resetAndHandleFilterChange = (name, value) => {
    resetAllFilters();
    handleFilterChange(name, value);
  };

  const resetAndHandleDateTimeFilterChange = (start, end, field = 'datetime') => {
    resetAllFilters();
    handleDateTimeFilterChange(start, end, field);
  };

  useEffect(() => {
    const apiFilters = {};

    apiFilters.show_completed = showCompletedAppointments;

    if (sortField) {
      apiFilters.sort = sortDirection === 'desc' ? `-${sortField}` : sortField;
    }

    if (filter?.name && filter?.value) apiFilters[filter.name] = filter.value;
    if (dateTimeFilter) {
      apiFilters[`${dateTimeFilter.field}_from`] = dateTimeFilter.start;
      apiFilters[`${dateTimeFilter.field}_to`] = dateTimeFilter.end;
    }
    apiFilters.page = currentPage;
    apiFilters.page_size = itemsPerPage;

    setFiltersObject(apiFilters);
  }, [showCompletedAppointments, sortField, sortDirection, filter, dateTimeFilter, currentPage, itemsPerPage, setFiltersObject]);

  useEffect(() => {
    if (!Object.keys(filtersObject).length) return;
    fetchAllAppointments();
  }, [fetchAllAppointments, filtersObject]);

  useEffect(() => {
    return () => {
      return resetAllFilters();
    };
  }, []);

  return (
    <>
      <AppointmentModal modalIsOpen={modalIsOpen} toggle={toggle} />
      <div className='container-fluid'>
        {loggedUser?.role === 'patient' && <PatientNextApptCard />}
        <AppointmentsListContainer>
          <div className='d-sm-flex align-items-center justify-content-between mb-3'>
            <div className='custom-control custom-switch'>
              <input
                type='checkbox'
                className='custom-control-input'
                id='showCompletedAppointmentsSwitch'
                checked={showCompletedAppointments}
                onChange={() => toggleShowCompletedAppointments(!showCompletedAppointments)}
              />
              <label className='custom-control-label' style={{ color: 'black' }} htmlFor='showCompletedAppointmentsSwitch'>
                <h4 className='m-0'>Include Completed and Expired Appointments</h4>
              </label>
            </div>
            {loggedUser.role !== 'patient' && (
              <button className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm' type='button' onClick={toggle}>
                <i className='fas fa-plus fa-sm text-white-50'></i> Add
              </button>
            )}
          </div>
          <AppointmentsList
            loggedUser={loggedUser}
            showCompletedAppointments={showCompletedAppointments}
            itemsPerPage={itemsPerPage}
            allAppointments={allAppointments?.results || []}
            totalRows={allAppointments?.count}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onFilterChange={resetAndHandleFilterChange}
            onSort={handleSort}
            onDateTimeFilterChange={resetAndHandleDateTimeFilterChange}
            resetAllFilters={resetAllFilters}
          />
        </AppointmentsListContainer>
      </div>
    </>
  );
};

const AppointmentsListContainer = ({ children }) => {
  const [apptsExpanded, setApptsExpanded] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  if (loggedUser?.role !== 'patient') return <>{children}</>;
  return (
    <div className={'border border-dark rounded p-3 mb-4 bg-white overflow-hidden'} style={{ height: apptsExpanded ? 'auto' : '70px' }}>
      <div className='d-flex justify-content-center'>
        <button className={`btn btn-${apptsExpanded ? 'danger' : 'ibis'} shadow-sm w-25 mb-5`} onClick={() => setApptsExpanded(!apptsExpanded)}>
          {apptsExpanded ? 'Hide' : 'Show All Appointments'}
        </button>
      </div>
      {children}
    </div>
  );
};

export default AppointmentContent;
