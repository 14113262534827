import { createContext, useCallback, useContext, useState } from 'react';
import { getAllAppointments } from '../api/appointmentFunctions';
import useFilter from '../hooks/useFilter';
import usePagination from '../hooks/usePagination';
import useSort from '../hooks/useSort';

import { useCrud } from '../context/crudContext';

const AppointmentContext = createContext();

export const useAppointment = () => {
  return useContext(AppointmentContext);
};

export const AppointmentProvider = ({ children }) => {
  const { fetchObjects, addObject, saveObject, detailObject } = useCrud();
  const [appointments, setAppointments] = useState(null);
  const [allAppointments, setAllAppointments] = useState({});
  const { currentPage, itemsPerPage, handlePageChange, handleRowsPerPageChange, resetPagination } = usePagination(1, 10);
  const { filter, handleFilterChange, dateTimeFilter, handleDateTimeFilterChange, resetFilters } = useFilter();
  const { sortField, sortDirection, handleSort, resetSort } = useSort('start', 'desc');
  const [filtersObject, setFiltersObject] = useState({});

  const getApiUrl = (appointmentId) => {
    if (appointmentId) {
      return `/api/appointments/${appointmentId}/`;
    }

    return '/api/appointments/';
  };

  const resetAllFilters = () => {
    resetPagination();
    resetFilters();
    resetSort();
  };

  const fetchAllAppointments = useCallback(async () => {
    const res = await getAllAppointments(filtersObject);
    setAllAppointments(res);
  }, [filtersObject]);

  const fetchAppointments = async (filterParam = '') => {
    return await fetchObjects(`${getApiUrl()}/${filterParam}`);
  };

  const getAppointments = async (filterParam) => {
    const dataFromServer = await fetchAppointments(filterParam);
    const appointmentsRecords = dataFromServer.results || [];

    setAppointments(appointmentsRecords);

    return dataFromServer;
  };

  const addAppointment = async (appointmentData) => {
    const newAppointment = await addObject(getApiUrl(), appointmentData);
    if (typeof newAppointment == 'object') {
      window.location.reload();
    }
  };

  const saveAppointment = async (appointmentId, appointmentData) => {
    delete appointmentData.id;
    const existentAppointment = await saveObject(getApiUrl(appointmentId), appointmentData);
    if (typeof existentAppointment == 'object') {
      getAppointments();
      return existentAppointment;
    }
  };

  const editAppointment = async (appointmentId) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const res = await detailObject(getApiUrl(appointmentId), 'GET');
    const appointmentObject = await res.json();

    for (const fieldname in appointmentObject) {
      const field_element = document.getElementById('edit_field_' + fieldname);
      if (fieldname === 'start' || fieldname === 'expected_end') {
        let datetime_field = appointmentObject[fieldname].split(' ');
        let field_date = document.getElementById('edit_field_' + fieldname + '_date');
        let field_time = document.getElementById('edit_field_' + fieldname + '_time');
        if (field_date && field_time) {
          field_date.value = datetime_field[0];
          field_time.value = datetime_field[1];
        }
      }
      if (field_element) {
        field_element.value = appointmentObject[fieldname];
      }
    }
    return appointmentObject;
  };

  const deleteAppointment = async (appointmentId) => {
    if (window.confirm('Are you sure you want to delete this appointment?') === true) {
      await detailObject(getApiUrl(appointmentId), 'DELETE');
      await getAppointments();
      return true;
    } else {
      return false;
    }
  };

  const getCurrentAppointment = async (questionnaire) => {
    const currentAppointment = await detailObject(getApiUrl('current'));
    const response = await currentAppointment.json();
    return response;
  };

  return (
    <AppointmentContext.Provider
      value={{
        appointments,
        getAppointments,
        setAppointments,
        addAppointment,
        editAppointment,
        deleteAppointment,
        saveAppointment,
        getCurrentAppointment,
        allAppointments,
        setAllAppointments,
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        resetPagination,
        filter,
        handleFilterChange,
        dateTimeFilter,
        handleDateTimeFilterChange,
        resetFilters,
        sortField,
        sortDirection,
        handleSort,
        resetSort,
        resetAllFilters,
        filtersObject,
        setFiltersObject,
        fetchAllAppointments,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
