import { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { useModal, usePopover } from '../../hooks';
import SessionsDataTable from '../DataTable/SessionsDataTable';
import AppointmentActionsPopover from './AppointmentActionsPopover';
import EditAppointmentForm from './EditAppointmentForm';

const AppointmentsList = ({
  loggedUser,
  showCompletedAppointments,
  allAppointments,
  totalRows,
  itemsPerPage,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
  onFilterChange,
  onSort,
  onDateTimeFilterChange,
  resetAllFilters,
}) => {
  const { modalIsOpen, toggle } = useModal();
  const [appointmentToEdit, setAppointmentToEdit] = useState(null);
  const history = useHistory();
  const { showPopover, setShowPopover } = usePopover();

  const getPreScreeningButton = (appointment) => {
    const {
      questionnaire_id,
      prescreening: { questionnaires, tests, prescription },
    } = appointment;

    const showBtn = questionnaire_id || questionnaires.length || tests.length || Object.keys(prescription).length;
    if (!showBtn) {
      return 'None';
    } else {
      return (
        <button
          className={`btn btn-ibis ${loggedUser.role === 'patient' ? null : 'btn-circle'}`}
          onClick={() => history.push(`/pre-screening/${appointment.id}`)}
        >
          <i className='fas fa-list'></i>
          {loggedUser.role === 'patient' ? `\u0020Complete Pre-Screening` : null}
        </button>
      );
    }
  };

  const generateStartButton = (appointment) => {
    if (loggedUser.role === 'patient') {
      window.open(
        `/health/?userID=${loggedUser.id}&userName=${loggedUser.username}&userEmail=${loggedUser.email}&roomName=${appointment.room_name}`,
        '_blank'
      );
    } else {
      window.open(`/start/${appointment.room_name}`, '_blank');
    }
  };

  const getStartButton = (appointment) => {
    return (
      <button
        className={`btn btn-ibis ${loggedUser.role === 'patient' ? null : 'btn-circle'}`}
        onClick={() => generateStartButton(appointment)}
        disabled={appointment.status !== 'open'}
      >
        <i className='fas fa-sign-in-alt'></i>
        {loggedUser.role === 'patient' ? `\u0020Start Session` : null}
      </button>
    );
  };

  const getActionsButton = (appointment) => {
    if (appointment.status !== 'open') {
      return (
        <button
          className='btn btn-ibis'
          style={{ padding: '0.25rem 0.625rem', fontSize: '0.825rem' }}
          onClick={() => window.open(`appointment-results/${appointment.id}`, '_blank')}
        >
          Results
        </button>
      );
    }
    return (
      <>
        <button
          type='button'
          id={`action-btn-${appointment.id}`}
          className='btn btn-md'
          onClick={() => {
            handlePopover(appointment.id);
          }}
          onBlur={() => setShowPopover(false)}
        >
          <i className='fas fa-ellipsis-v'></i>
        </button>
        <AppointmentActionsPopover
          id={appointment.id}
          showPopover={showPopover === appointment.id}
          setAppointmentToEdit={setAppointmentToEdit}
          openEditModal={toggle}
        />
      </>
    );
  };

  const handlePopover = (id) => {
    if (showPopover === id) {
      setShowPopover(false);
    } else setShowPopover(id);
  };

  const columns = [
    {
      name: 'Clinician',
      selector: (appointment) => appointment.optician_full_name,
      sortable: true,
      omit: loggedUser.role === 'optician',
      wrap: true,
      maxWidth: '130px',
      filter_field: 'optician',
      filter_type: 'alphabetical',
      sortField: 'optician',
    },
    {
      name: 'Clinician Company',
      selector: (appointment) => appointment.patient_company,
      filter_field: 'optician_company',
      omit: !loggedUser?.superuser,
      wrap: true,
      filter_type: 'alphabetical',
    },
    {
      name: 'Patient',
      selector: (appointment) => appointment.patient_full_name,
      sortable: true,
      omit: loggedUser.role === 'patient',
      wrap: true,
      maxWidth: '120px',
      filter_field: 'patient',
      filter_type: 'alphabetical',
      sortField: 'patient',
    },
    {
      name: 'Patient Company',
      selector: (appointment) => appointment.patient_company,
      filter_field: 'patient_company',
      omit: !loggedUser?.superuser,
      wrap: true,
      filter_type: 'alphabetical',
    },
    {
      name: 'Start Time',
      selector: (appointment) => appointment.start,
      sortable: true,
      wrap: true,
      filter_field: 'start',
      filter_type: 'datetime',
      sortField: 'start',
    },
    {
      name: 'End Time',
      selector: (appointment) => appointment.expected_end,
      sortable: true,
      wrap: true,
      filter_field: 'expected_end',
      filter_type: 'datetime',
      sortField: 'expected_end',
    },
    {
      name: 'Status',
      selector: (appointment) => appointment.status.charAt(0).toUpperCase() + appointment.status.slice(1),
      sortable: true,
      omit: !showCompletedAppointments,
      maxWidth: '110px',
      filter_field: 'status',
      filter_type: 'alphabetical',
    },
    {
      name: 'Pre-Screening',
      selector: (appointment) => getPreScreeningButton(appointment),
      style: { '& > div': { padding: 0 } },
      button: true,
      minWidth: loggedUser.role === 'patient' ? '275px' : '140px',
      filter_type: 'alphabetical',
    },
    {
      name: 'Edit',
      cell: (appointment) => getActionsButton(appointment),
      omit: loggedUser.role === 'patient',
      button: true,
      width: '80px',
    },
    {
      name: loggedUser.role === 'optician' ? 'Start' : 'Session',
      selector: (appointment) => getStartButton(appointment),
      omit: loggedUser.role === 'admin',
      style: { '& > div': { padding: 0 } },
      button: true,
      minWidth: loggedUser.role === 'patient' ? '175px' : null,
    },
  ];

  const filterOptions = columns
    .filter((column) => column.filter_field)
    .map((column) => ({
      name: column.name,
      field: column.filter_field,
      type: column.filter_type || 'text',
    }));

  return (
    <div className='container-fluid'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggle}
        role='document'
        appElement={document.getElementById('wrapper')}
        closeTimeoutMS={300}
        className='Modal__Bootstrap modal-dialog'
      >
        <EditAppointmentForm loggedUser={loggedUser} hide={toggle} appointment={appointmentToEdit} />
      </Modal>
      <SessionsDataTable
        title='Appointments'
        data={allAppointments}
        columns={columns}
        defaultSortFieldId={2}
        defaultSortAsc={false}
        totalRows={totalRows}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onFilterChange={onFilterChange}
        onSort={onSort}
        onDateTimeFilterChange={onDateTimeFilterChange}
        filterOptions={filterOptions}
        resetAllFilters={resetAllFilters}
      />
    </div>
  );
};

export default AppointmentsList;
