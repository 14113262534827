import { usePopover } from '../../hooks';
import SessionsDataTable from '../DataTable/SessionsDataTable';
import RecheckActionsPopover from './RecheckActionsPopover';

const RecheckSessionsList = ({
  sessions,
  totalRows,
  itemsPerPage,
  currentPage,
  onPageChange,
  onRowsPerPageChange,
  onFilterChange,
  onSort,
  onDateTimeFilterChange,
  resetAllFilters
}) => {
  const { showPopover, setShowPopover } = usePopover();

  const getActionsButton = (session) => {
    return (
      <>
        <button
          type='button'
          id={`action-btn-${session.id}`}
          className='btn btn-md'
          onClick={() => handlePopover(session.id)}
          onBlur={() => setShowPopover(false)}
        >
          <i className='fas fa-ellipsis-v' />
        </button>
        <RecheckActionsPopover session={session} showPopover={showPopover === session.id} setShowPopover={setShowPopover} />
      </>
    );
  };

  const handlePopover = (id) => {
    if (showPopover === id) {
      setShowPopover(false);
    } else setShowPopover(id);
  };

  const columns = [
    {
      name: 'Session ID',
      selector: (session) => session.id,
      sortable: true,
      filter_field: 'id',
      width: '130px',
      sortField: 'id',
      filter_type: 'numerical',
    },
    {
      name: 'Patient',
      selector: (session) => session.user,
      sortable: true,
      wrap: true,
      filter_field: 'patient',
      sortField: 'patient',
      filter_type: 'alphabetical',
    },
    {
      name: 'Date',
      selector: (session) => session.created,
      sortable: true,
      filter_field: 'datetime',
      sortField: 'created',
      filter_type: 'datetime',
    },
    {
      name: 'Status',
      selector: (session) => session.status,
      sortable: true,
      filter_field: 'status',
      style: { textTransform: 'capitalize' },
      sortField: 'status',
      filter_type: 'alphabetical',
    },
    {
      name: 'Latest activity',
      selector: (session) => session.latest_activity,
      sortable: true,
      filter_field: 'latest_activity',
      sortField: 'latest_activity',
      style: { textTransform: 'capitalize' },
      filter_type: 'alphabetical',
    },
    {
      name: 'Latest change',
      selector: (session) => session.latest_activity_datetime,
      sortable: true,
      filter_field: 'latest_activity_datetime',
      sortField: 'latest_activity_datetime',
      style: { textTransform: 'capitalize' },
      filter_type: 'datetime',
    },
    {
      name: 'Actions',
      cell: (session) => getActionsButton(session),
      button: true,
      width: '90px',
    },
  ];

  const filterOptions = columns
    .filter((column) => column.filter_field)
    .map((column) => ({
      name: column.name,
      field: column.filter_field,
      type: column.filter_type || 'text',
    }));

  return (
    <SessionsDataTable
      title='Recheck Sessions'
      data={sessions}
      columns={columns}
      defaultSortFieldId={2}
      defaultSortAsc={false}
      totalRows={totalRows}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onFilterChange={onFilterChange}
      onSort={onSort}
      onDateTimeFilterChange={onDateTimeFilterChange}
      filterOptions={filterOptions}
      resetAllFilters={resetAllFilters}
    />
  );
};

export default RecheckSessionsList;
