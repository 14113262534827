import { formatResult } from '../../../helper/recheck';

const NewTumblingEResult = ({ test, result }) => {
  const hasEyePassed = (eyeKey) => {
    let correctAnswersCount = 0;
    const ids = [9];
    const minimalCorrectAnswers = 1;
    Object.keys(result.testResultData[eyeKey]).forEach((logmarId) => {
      if (ids.includes(Number(logmarId))) {
        result.testResultData[eyeKey][logmarId].forEach((logmarResponse) => {
          if (logmarResponse.correct) {
            correctAnswersCount++;
          }
        });
      }
    });

    return correctAnswersCount >= minimalCorrectAnswers;
  };

  const hasResults = (eyeKey) => {
    return (
      typeof result.testResultData[eyeKey] === 'object' &&
      Object.keys(result.testResultData[eyeKey]).length &&
      Object.keys(result.testResultData[eyeKey]).every((key) => result.testResultData[eyeKey][key].length > 0)
    );
  };

  const resultTable = (eyeKey, title) => {
    return (
      <tr>
        <td className='font-weight-bold w-25'>{title}</td>
        {hasResults(eyeKey) ? (
          <td>{hasEyePassed(eyeKey) ? formatResult('Visual Acuity', true) : formatResult('Visual Acuity', false)}</td>
        ) : (
          <td>Not completed</td>
        )}
      </tr>
    );
  };
  return (
    <table className='table table-bordered w-75'>
      <thead>
        <tr>
          <th colSpan='2' className='text-center bg-primary-ibis'>
            {test}
          </th>
        </tr>
      </thead>
      <tbody>
        {!result ? (
          <tr>
            <td>No results found.</td>
          </tr>
        ) : (
          <>
            {resultTable('right-eye', 'OD')}
            {resultTable('left-eye', 'OS')}
          </>
        )}
      </tbody>
    </table>
  );
};

export default NewTumblingEResult;
