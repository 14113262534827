const NearVisionResult = ({ test, result }) => {
  return (
    <table className='table table-bordered w-75'>
      <thead>
        <tr>
          <th colSpan='2' className='text-center bg-primary-ibis'>
            {test}
          </th>
        </tr>
      </thead>
      <tbody>
        {!result?.testResultData?.binocular?.magnification ? (
          <tr>
            <td>Not completed</td>
          </tr>
        ) : (
          <>
            <tr>
              <td className='font-weight-bold w-25'>Suggested Magnification</td>
              <td>{result.testResultData.binocular.magnification}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default NearVisionResult;
